// p-datatable
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background-color: $primary-50;
}

.p-datatable .p-datatable-thead > tr > th {
    border: 1px solid $neutral-200;
    color: $neutral-800;
    font-size: 14px;
    font-weight: 400;
    padding: 23px 8px 3px 8px;
    background: $white;
    text-align: left;
    font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: 10px 8px 3px 10px;
    border: 1px solid  $neutral-200;
    color: $neutral-800;
    font-weight: 400;
    font-family: open sans,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 14px;
    white-space: nowrap;
}

.p-paginator  {
    width: 100px;
}

.k-grid .k-table-row.k-table-alt-row {
  background-color: $primary-50;
}

.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
.k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
  background-color: $primary-100;
}
