@tailwind base;
@tailwind components;
@tailwind utilities;
// font-family: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

/* You can add global styles to this file, and also import other style files */

// #region FONTS 
$mainFont: "Roboto", "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
// Ces graisses sont les graisses importées en Roboto
$fontLight: 300;
$fontRegular: 400;
$fontMedium: 500;
$fontBold: 700;
$fontBlack: 900;
// #endregion FONTS 

// #region PRIMARY COLORS
$primary-50: #E3F7FF;
$primary-100: #B3E9FC;
$primary-200: #7CDBFC;
$primary-300: #4DCAF7;
$primary-400: #23B6EB;
$primary-500: #1B9DCC;
$primary-600: #087EA8;
$primary-700: #07678A;
$primary-800: #004D68;
$primary-900: #00364A;
$primary: $primary-600;
$primaryActive: $primary-700;
$primaryHover: $primary-800;
// #endregion PRIMARY COLORS

// #region NEUTRAL COLORS
$neutral-0: #FFFFFF;
$neutral-25: #F7F9FA;
$neutral-50: #EDF2F6;
$neutral-75: #E4EEF5;
$neutral-100: #D8E5EB;
$neutral-200: #D1E0E8;
$neutral-300: #BFD1DB;
$neutral-400: #BFD1DB;
$neutral-500: #6F7A80;
$neutral-600: #646D75;
$neutral-700: #505A61;
$neutral-800: #40474D;
$neutral-900: #23272B;
$neutralBlack: #000000;
$neutral: $neutral-600;
$neutralActive: $neutral-700;
$neutralHover: $neutral-800;
// #endregion NEUTRAL COLORS

// #region SHADES COLORS
$shades-50: #F9F9F9;
$shades-100: #F2F2F2;
$shades-200: #EEEEEE;
$shades-300: #E3E3E3;
$shades-400: #CFCFCF;
$shades-500: #B5B5B5;
$shades-600: #969696;
$shades-700: #747474;
$shades-800: #4E4E4E;
$shades-900: #2D2D2D;
// #endregion SHADES COLORS

// #region SEMANTIC COLORS
$success-50: #DBFAE0;
$success-100: #BEF3C7;
$success-200: #A2EBAF;
$success-300: #84E695;
$success-400: #68D97B;
$success-500: #57C769;
$success-600: #39B155;
$success-700: #279B42;
$success-800: #0A762E;
$success-900: #004D20;
$success: $success-600;
$successActive: $success-700;
$successHover: $success-800;

$warning-50: #FFF2DB;
$warning-100: #FFE9C2;
$warning-200: #FFDB9E;
$warning-300: #FFD080;
$warning-400: #FFC35C;
$warning-500: #FFB13D;
$warning-600: #FF9D00;
$warning-700: #F08800;
$warning-800: #C25100;
$warning-900: #802A00;
$warning: $warning-600;
$warningActive: $warning-700;
$warningHover: $warning-800;

$error-50: #FFEBEC;
$error-100: #FFD6D8;
$error-200: #FDBEBE;
$error-300: #FAA3A3;
$error-400: #FA8989;
$error-500: #F76E6E;
$error-600: #F15050;
$error-700: #DA2F2F;
$error-800: #AA0E0E;
$error-900: #630303;
$error: $error-600;
$errorActive: $error-700;
$errorHover: $error-800;
// #endregion SEMANTIC COLORS

// #region ILLUSTRATIVE COLORS
// blue
$blue-50: #F5F9FF;
$blue-100: #D0E1FD;
$blue-200: #ABC9FB;
$blue-300: #85B2F9;
$blue-400: #609AF8;
$blue-500: #3B82F6;
$blue-600: #326FD1;
$blue-700: #295BAC;
$blue-800: #204887;
$blue-900: #183462;

// green
$green-50: #F4FCF7;
$green-100: #CAF1D8;
$green-200: #A0E6BA;
$green-300: #76DB9B;
$green-400: #4CD07D;
$green-500: #22C55E;
$green-600: #1DA750;
$green-700: #188A42;
$green-800: #136C34;
$green-900: #0E4F26;

// yellow
$yellow-50: #FEFBF3;
$yellow-100: #FAEDC4;
$yellow-200: #F6DE95;
$yellow-300: #F2D066;
$yellow-400: #EEC137;
$yellow-500: #EAB308;
$yellow-600: #C79807;
$yellow-700: #A47D06;
$yellow-800: #816204;
$yellow-900: #5E4803;

// cyan
$cyan-50: #F3FBFD;
$cyan-100: #C3EDF5;
$cyan-200: #94E0ED;
$cyan-300: #65D2E4;
$cyan-400: #35C4DC;
$cyan-500: #06B6D4;
$cyan-600: #059BB4;
$cyan-700: #047F94;
$cyan-800: #036475;
$cyan-900: #024955;

// pink
$pink-50: #FEF6FA;
$pink-100: #FAD3E7;
$pink-200: #F7B0D3;
$pink-300: #F38EC0;
$pink-400: #F06BAC;
$pink-500: #EC4899;
$pink-600: #C93D82;
$pink-700: #A5326B;
$pink-800: #822854;
$pink-900: #5E1D3D;

// indigo
$indigo-50: #F7F7FE;
$indigo-100: #DADAFC;
$indigo-200: #BCBDF9;
$indigo-300: #9EA0F6;
$indigo-400: #8183F4;
$indigo-500: #6366F1;
$indigo-600: #5457CD;
$indigo-700: #4547A9;
$indigo-800: #363885;
$indigo-900: #282960;

// teal
$teal-50: #F3FBFB;
$teal-100: #C7EEEA;
$teal-200: #9AE0D9;
$teal-300: #6DD3C8;
$teal-400: #41C5B7;
$teal-500: #14B8A6;
$teal-600: #119C8D;
$teal-700: #0E8174;
$teal-800: #0B655B;
$teal-900: #084A42;

// orange
$orange-50: #FFF8F3;
$orange-100: #FFE5D0;
$orange-200: #FECBA1;
$orange-300: #FBA86F;
$orange-400: #FA8E42;
$orange-500: #F97316;
$orange-600: #D46213;
$orange-700: #AE510F;
$orange-800: #893F0C;
$orange-900: #642E09;

// bluegray
$bluegray-50: #F7F8F9;
$bluegray-100: #DADEE3;
$bluegray-200: #BCC3CD;
$bluegray-300: #9FA9B7;
$bluegray-400: #818EA1;
$bluegray-500: #64748B;
$bluegray-600: #556376;
$bluegray-700: #465161;
$bluegray-800: #37404C;
$bluegray-900: #282E38;

// purple
$purple-50: #FBF7FF;
$purple-100: #EAD6FD;
$purple-200: #DAB6FC;
$purple-300: #C996FA;
$purple-400: #B975F9;
$purple-500: #A855F7;
$purple-600: #8F48D2;
$purple-700: #763CAD;
$purple-800: #5C2F88;
$purple-900: #432263;

// red
$red-50: #FFF5F5;
$red-100: #FFD0CE;
$red-200: #FFACA7;
$red-300: #FF8780;
$red-400: #FF6259;
$red-500: #FF3D32;
$red-600: #D9342B;
$red-700: #B32B23;
$red-800: #8C221C;
$red-900: #661814;
// #endregion ILLUSTRATIVE COLORS

// #region COLORS
$bgWhite: #F7F8FA;
$white: #FFFFFF;
$black: #000000;
$grey: #5e5e5e;
$gray: #78929ED9;
$lightGrey: #d1dade;
$primary: $primary;
$primary-2: $primary-900;
$primary-3: $primary-700;
$secondary: #067893;
$tertiary: #136D89;
$text: $neutral-600;
$textHigh: $neutral-800;
$textLow: $neutral-500;
$lowBgDisable: $neutral-300;
$prune: #9020bd;
$lightPrune: #b99cc5;
$success: #69AA1E;
$green: #37bc9b;
$red: #ed5565;
$orange: #FF7733;
$orange-border: #DA662C;
$alert: #FF3E38;
$alertLight: #FFE5D7;
$borderColor: #e4e5e7;
$oops: #223350;
$oops-message: $primary-500;
$oops-background: #f5f7fa;
// #endregion COLORS


// #region VARIABLES
$borderRadius: 6px;
// #endregion VARIABLES

// #region MEDIA QUERIES & BREAKPOINTS
// BREAKPOINTS
$breakpoint-xs: 640px;
$breakpoint-s: 800px;
$breakpoint-m: 1280px;
$breakpoint-l: 1440px;
$breakpoint-xl: 1600px;

// #region MEDIA QUERIES & BREAKPOINTS
@mixin screenSizeXS {
    @media screen and (max-width: $breakpoint-xs) {
        @content;
    }
}
@mixin screenSizeS {
    @media screen and (max-width: $breakpoint-s) {
        @content;
    }
}
@mixin screenSizeM {
    @media screen and (max-width: $breakpoint-m) {
        @content;
    }
}
@mixin screenSizeL {
    @media screen and (max-width: $breakpoint-l) {
        @content;
    }
}
@mixin screenSizeXL {
    @media screen and (min-width: $breakpoint-xl) {
        @content;
    }
}
// #endregion MEDIA QUERIES & BREAKPOINTS

// #region SPACING
/// xxs
$spacing-04: 4px;
/// xs
$spacing-08: 8px;
/// s
$spacing-16: 16px;
/// m
$spacing-24: 24px;
/// 2m
$spacing-32: 32px;
/// l
$spacing-40: 40px;
/// xl
$spacing-48: 48px;
/// 2xl
$spacing-96: 96px;
// #endregion SPACING

/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }

body {
    margin: 0;
    font-family: $mainFont;
    font-weight: $fontRegular !important;
    font-size: 13px !important;
    font-display: auto;
    scrollbar-color: $neutral-100 $neutral-300;
}


.mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: $neutral-800 !important;
    --mdc-plain-tooltip-text-align: center !important;
    font-size: 11px !important;
    text-align: center !important;
}

// #region FLEXBOXES
@mixin flexCol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

@mixin flexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

@mixin flexCenter {
    justify-content: center;
    align-items: center;
}
// container infosIdentite , infosFinancier , infosReleve (abonnement synthese)
@mixin flexContainer {
    @include flexRow;
    flex-wrap: wrap;
    box-sizing: border-box;
    box-shadow: rgb(194 194 194 / 50%) 1px 1px 2px;
    border: 1px solid $borderColor;
    position: relative;
    padding: 20px;
    border-radius: 1px;
    background-color: $white;
    color: $neutral-800;
}
// #endregion FLEXBOXES

// #region VARIABLES
$borderRadius: 6px;
// #endregion VARIABLES

// #region animation boucein right
@-webkit-keyframes bounceInRight {
    0% {
       opacity: 0;
       -webkit-transform: translateX(2000px);
    }
    60% {
       opacity: 1;
       -webkit-transform: translateX(-30px);
    }
    80% {
       -webkit-transform: translateX(10px);
    }
    100% {
       -webkit-transform: translateX(0);
    }
}

@keyframes bounceInRight {
    0% {
       opacity: 0;
       transform: translateX(2000px);
    }
    60% {
       opacity: 1;
       transform: translateX(-30px);
    }
    80% {
       transform: translateX(10px);
    }
    100% {
       transform: translateX(0);
    }
}
// Placeholders
%bounceInFrame {
    -webkit-animation-name: bounceInRight;
    animation-name: bounceInRight;
    margin: 20px;
    color: $neutral-700;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
// #endregion animation boucein right

.mat-drawer-content {
    z-index: initial !important;
    height: 100dvh !important;
}

.spinner-icon {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: white;
    text-align: center;
    box-sizing: border-box;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome, sans-serif;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: 1em;
    animation: fa-spin 1s infinite linear;
    margin-right: 10px;
}

.required:after {
  content:" *";
}

@import './primeng';
@import './angular';
@import './dropdown';
@import './tooltip';
@import './input';
@import './button';
@import './datatable';
@import './divider';
@import './dynamicDialog';
@import './overlayPanel';
@import './tabs';
@import './toast';
@import './tree';
@import './form';
@import './fieldset';
@import './chip';
@import './modal';
@import './kendo';
@import './card';
@import './swal';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "node_modules/primeicons/primeicons.css";
@import "node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import 'node_modules/primeng/resources/primeng.min';
