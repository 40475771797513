.waterp-tabs .p-tabview .p-tabview-panels {
    padding: 1.25rem 0;
    background-color: transparent;
}

.waterp-tabs{
    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
        border-color: $primary-700;
        color: $primary-700;
    }

    .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{
        box-shadow: inset 0 0 0 0.2rem $primary-100
    }
}
