
// p-button
.btn-search {
    background-color: transparent !important;
    color: black !important;
    border: 1px solid #ccc !important;
    &:hover {
        background-color: lightgrey !important;
    }
}

// p-element btn-inputgroup btn-right p-button p-component p-button-icon-only
.p-element.p-button.p-component.btn-inputgroup {
    background-color: transparent;
    border: 1px solid #ccc;
    color: black;
    width: 30px;
    height: 30px;

    &:hover {
        background-color: #ccc;
        color: black;
        border: 0;
    }

    &.btn-left {
        border-right: 0px;
    }

    &.btn-right {
        border-left: 0;
    }
}
// p-panel

.p-panel .p-panel-header {
    background: $neutral-200;
    height: 42px;
}
.p-panel-title {
    color: #477084;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;
    font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.panel-editor > * div.p-panel-content {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
}

// p-datatable

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
    background-color: #d8f1f9;
}

.p-datatable .p-datatable-thead > tr > th {
    padding: 0.6em;
    border-top: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    background-color: $white;
    color: $primary;
    font-weight: 600;
    font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 13px;
}

.p-datatable .p-datatable-tbody > tr > td {
    border-left: 1px solid #dee2e6;
    border-bottom: none;
    font-weight: 600;
    font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px;
}

.p-paginator  {
    width: 100px;
}

// radio button
.p-radiobutton {
    .p-radiobutton-box {
        height: 20px;
        width: 20px;
        .p-radiobutton-icon {
            background: $primary;
        }
        &.p-highlight {
            background: transparent;
        }
        &.p-highlight:not(.p-disabled){
             border-color: $neutral-600;
             background: $primary;
            &:hover {
                border-color: $primary;
                background: $primary;
            }
        }
        &:hover {
            border-color: $primary !important;
        }
    }
    &:hover {
        border-color: $primary;
    }
}

// checkbox
p-checkbox.urgent {
    .p-checkbox .p-checkbox-box.p-highlight {
      border-color: $error-700;
      background: $error-700;
      &:hover {
          border-color: $error-700;
          background: $error-700;

      }
    }
    .p-checkbox-box.p-focus {
      border-color: $neutral-300;
    }
  }
p-checkbox.urgentDisabled {
    .p-checkbox {
      .p-checkbox-box.p-highlight {
        border-color: $error-700;
        background: $error-700;
      }
    }
  }

.p-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .p-checkbox-box {
        height: 18px;
        width: 18px;
        border-radius: 4px;
        &.p-highlight {
            border-color: $primary;
            background: $primary;
            &:hover {
                border-color: $primary;
                background: $primary;
                color: $white;
            }
        }
        .p-checkbox-icon {
            font-size: 10px;
            font-weight: bold;
        }
    }
    &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
            &:hover {
                border-color: $primary;
            }
            &.p-highlight {
                &:hover {
                    border-color: $primary;
                    background: $primary;
                }
            }
            &.p-focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: none;
                border-color: $primary;
            }
        }
    }
}
.p-tabview {
    .p-tabview-nav {
        border: none;
        li {
            .p-tabview-nav-link {
                border: none;
                border-radius: 0;
                background: $white;
                color: $primary;
                padding: 1.25rem;
                font-weight: normal;
                font-size: 14px;
                transition: box-shadow 0.1s;
                margin: 0 0 -2px 0;
                &:not(.p-disabled):focus  {
                    box-shadow: none;
                }
            }
            &.p-tabview-ink-bar {
                background-color: $primary;
            }
            &.p-highlight {
                font-weight: bold;
                border: 0;
                .p-tabview-nav-link {
                    font-weight: bold;
                    color: #555;
                }
            }
            &:not(.p-highlight):not(.p-disabled):hover {
                .p-tabview-nav-link {
                    background-color: #a7e0f1;
                    border-color: transparent;
                    color: #676a6c;
                }
            }
        }
    }
}
// component
.p-component {
    font-size: 13px;
    font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif !important;
}
.p-paginator, .p-paginator-bottom {
    width: 100%;
}
.p-paginator .p-dropdown {
    width: 100px;
    .p-element.p-dropdown-label.p-inputtext.ng-star-inserted {
        padding-top: 12px;
        padding-bottom: 0px;
        padding-left: 12px;
        font-size: 16px;
    }
}
.p-ripple.p-element.p-dropdown-item {
    text-align: left;
    font-size: 11px;
    padding: 5px;
    &.p-highlight {
        color:$white;
        background: $primary;
    }
    &:not(.p-highlight):not(.p-disabled):hover {
        color: $primary;
        background: $white;
    }
}


/* P-accordion */
::ng-deep .p-accordion .p-accordion-content {
  padding: 0rem 1.25rem 1.25rem 1.25rem;
  border: 1px solid transparent !important;
  background: $white;
  color: #477084;
}

::ng-deep .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
::ng-deep .p-accordion .p-accordion-header .p-accordion-header-link,
::ng-deep .p-accordion-header .p-accordion-header-link {
  background: transparent !important;
  border-color: transparent !important;
  color: $primary-600 !important;

}

::ng-deep .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
}

::ng-deep .p-accordion .p-accordion-header .p-accordion-header-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 0.25rem;
  border: 1px solid transparent !important;
  color: #6c757d;
  background: transparent !important;
}

::ng-deep .k-grid-md .k-table-th > .k-cell-inner > .k-link {
  color: $primary !important;
}

::ng-deep .k-grid-header .k-sort-icon,
.k-grid-header .k-sort-order {
  color: #477084 !important;
}

::ng-deep input.p-dropdown-filter.p-inputtext.p-component {
  padding-left: 5px;
  padding-right: 5px;
}
/**/


//#region input switch button
::ng-deep .p-inputswitch {
    background-color: transparent;
}

::ng-deep .p-inputswitch-checked .p-inputswitch-slider {
    background: $primary-600 !important;
}
//#endregion input switch button

//#region datepicker / calendar
::ng-deep button.p-datepicker-month.p-link:hover,
::ng-deep button.p-datepicker-year.p-link:hover {
  background-color: $primary !important;
  color: $white !important;
}

::ng-deep .p-datepicker {
    transform:scale(0.7);
}
//#endregion datepicker / calendar
