// input number
input[type=number] {
    vertical-align: middle;
    border: 1px solid $neutral-300;
    background: $white;
    line-height: 30px;
    padding-left: 8px;
    &:hover {
        background-color: $neutral-100;
        border-color: $neutral-300;
    }
    &:focus{
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        border-color: $primary-700;
    }
}
// input email, date, number, text
input[type="email"], input[type="date"], input[type="number"], input[type="text"]:not(.p-inputtext-sm) {
    line-height: 30px;
    background-color: $white;
    background-image: none;
    border: 1px solid  $neutral-300;
    border-radius: 6px;
    color: $neutral-800;
    padding: 0 5px 0 5px;
    width: 100%;
    &:focus-visible {
        outline: none;
        border-color: $primary-700;
    }
    &:enabled {
        &:hover {
            border-color: $primary-700;
            outline: none;
        }
    }
    &.input-readOnly{
        border: 1px solid $neutral-200;
        background-color: $neutral-50 !important;
        color: $neutral-800;
        cursor: no-drop;
        &:enabled:hover {
            border-color: $neutral-200;  
        }
    }
}

// input date
input[type="date"]{
    line-height: 27px;
    background-color: $white;
    background-image: none;
    border: 1px solid  $neutral-300;
    border-radius: 6px;
    color: $neutral-800;
    padding-left: 5px;
    &:focus-visible {
        outline: none;
        border-color: $primary-700;
    }
    &:enabled {
        &:hover {
            border-color: $primary-700;
            outline: none;
        }
    }
}

// input date
input[type="date"]{
    line-height: 27px;
    background-color: $white;
    background-image: none;
    border: 1px solid  $neutral-300;
    border-radius: 6px;
    color: $neutral-800;
    padding-left: 5px;
    &:focus-visible {
        outline: none;
        border-color: $primary-700;
    }
    &:enabled {
        &:hover {
            border-color: $primary-700;
            outline: none;
        }
    }
}


// checkbox primeng
.checkbox {
    @include flexRow;
    flex-wrap: nowrap;
    margin-top: auto;
    margin-bottom: auto;
    
    label {
        margin-right: 35px;
        width: fit-content;
        color: $neutral-800;
        margin-left: 5px;
        margin-top: auto;
        margin-bottom: auto;
    }
}


// checkbox primeng
.p-checkbox, input[type="checkbox"] {
    background-color: $white;
    background-image: none;
    vertical-align: middle;
    border-color: $neutral-300;
    border-radius: 4px;
    accent-color: $primary;
    outline: none;
    &:not(.k-checkbox) {
        width: auto;
        height: auto;
    }
    &:focus-visible {
        outline: none;
        border-color: $primary-700;
    }
    &:enabled {
        &:hover {
            border-color: $primary-700;
            outline: none;
        }
    }
}


// input primeng
.p-inputtext {
    border-radius: 0px;
    &:enabled {
        &:hover {
            border-color: $primary-700;
        }
    }
}

// disabled input
input.disabled, input:disabled{
    border: 1px solid $neutral-200;
    background-color: $neutral-50 !important;
    color: $neutral-300;
    cursor: no-drop;
    &:enabled:hover {
        border-color: $neutral-200;
    }
}

// input number
.waterp-inputnumber-wrapped > .p-inputnumber {
    max-height: 32px;
    .p-inputnumber-input {
        width: 1%;
        border-radius: 6px;
    }
}

.waterp-inputnumber-full {
    width: 100%;
    .p-inputnumber {
        width: 100%;
        max-height: 32px;
        .p-inputnumber-input {
            border-radius: 6px;
        }
    }
}

.waterp-inputnumber {
    .p-inputnumber:not(.p-inputnumber-button-group) {
        max-height: 32px;
        width: 100%;
        .p-inputnumber-input {
            border-radius: 6px;
        }
    }
    .p-inputnumber:has(.p-inputnumber-button-group) {
        max-height: 32px;
        .p-inputnumber-input {
            border-radius: 6px 0px 0px 6px;
        }
        .p-inputnumber-button-group {
            .p-button.p-button-icon-only {
                width: 1.5rem;
            }
            .p-button {
                background-color: $primary-700;
                border: 1px solid $primary-700;
            }
        }
    }
}
