button {
    background-color: $primary;
    color: $white;
    border: none;
    cursor: pointer;
    &:hover:not(.mdc-switch) {
        background-color: $primary-900;
    }
    &:hover.kendo-toolbar-btn {
        background-color: $neutral-300;
    }
    &:focus.kendo-toolbar-btn {
        background-color: $neutral-400;
    }
    &:hover.light-btn {
        background-color: $primary-500;
        color: $white;
    }
    &:focus.light-btn {
        background-color: $white;
    }
    &:focus:not(.mdc-switch), &:focus:not(.light-btn),&:focus:not(.outline-button) {
        background-color: $primary-700;
    }
    &:focus.light-btn {
        background-color: $primary-600;
    }
    &.disabled:not(.mdc-switch),  &:disabled {
        opacity: 50% !important;
        cursor:not-allowed;
        &:hover {
            opacity: 50% !important;
            cursor:not-allowed;
        }
    }
    &.icon-button {
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        width: 37px;
        height: 37px;
        border-radius: 6px;
        line-height: 37px;
        i , fa-icon{
            font-size: 22px;
        }
    }
    &.small-primary-button {
        padding: 4px 8px 4px 8px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
    }
    &.button-icon {
      border-radius: 5px;
      padding: 4px 8px 4px 8px;
      background: $primary-700;
      &.disabled {
        background-color: $neutral-300 !important;
        cursor:not-allowed;
        &:hover {
            background-color: $neutral-300;
            cursor:not-allowed;
        }
      }
    }
    &.button-submit {
        border-radius: 5px;
        padding: 4px 8px 4px 8px;
        background: $primary-700;
        .ng-fa-icon {
            padding-right: 8px;
        }
    }
    &.outline-button {
        background: white;
        white-space: nowrap;
        border-radius: 5px;
        padding: 4px 8px 4px 8px;
        border: 1px solid $primary-700; 
        color: $primary-700;
        fa-icon {
            padding-right: 4px;
        }
        &:hover {
            color: white;
        }
    }
    &.link {
      background: white;
      white-space: nowrap;
      padding: 0px;
      border: 0px; 
      color: $primary-700;
      fa-icon {
          padding-right: 4px;
      }
      &:hover, &:focus {
        background: transparent;
    }
  }
    &.button-cancel {
        color: $primary-900;
        background: transparent;
        margin-right: 15px;
        font-weight: 600;
        .icon {
            color: $primary-900;
            transform: rotate(90deg);
            display: inline-table;
            font-size: 14px;
            position: relative;
            top: 3px;
        }
        &:hover, &:focus {
            background: transparent;
        }
    }
    &.secondary{
      background-color: $white;
      color: $primary-900;
      border: 1px solid $primary-900;

      &:hover:not(.mdc-switch) {
        background-color: $primary-900;
        color: $white;
      }
      &:focus:not(.mdc-switch) {
          background-color: $primary-900;
          color: $white;
      }
      &:disabled {
          background-color: $white;
          color: $white;
          border: none;
      }
    }
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button,
.ae-font .ae-picker-label {
  color: black;
}


