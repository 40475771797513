// var
$padding-l : 16px;
$item-height: 36px;
$border-radius: 6px;
// dropdown
.p-dropdown {
    border: 1px solid;
    border-color: $neutral-300;
    background: $white;
    cursor: pointer;
    vertical-align: middle;
    border-radius: $border-radius;
    width: 100%;
    height: 32px;
    .badge {
        background-color: $lightGrey;
        color: $grey;
        font-size: 11px;
        font-weight: 600;
        padding: 3px 6px;
        text-shadow: none;
        width: 25px;
        margin-left: 2px;
        margin-right: 8px;
        border-radius: 10px;
        vertical-align: middle;
    }
    &:not(.p-disabled) {
        &:hover {
            background-color: $neutral-100;
            border-color: $neutral-300;
        }
        &.p-focus {
            box-shadow: none;
            border-color: $primary-700;
            border: 1.6px solid;
            outline: 0 none;
            outline-offset: 0;
        }
        &:focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow: none;
            border-color: $primary-700;
        }
        &.p-dropdown-open {
            border-color: $primary-700;
            border: 1.6px solid;
            background-color: $neutral-50;
        }
    }
    &.p-disabled {
        background-color: $neutral-100;
        border-color: $neutral-300;
    }
    .p-dropdown-label.p-inputtext.ng-star-inserted {
        font-size: 14px;
        color: $neutral-800;
        font-weight: 400;
        padding-left: $padding-l;
        padding-top: 0PX;
        padding-right: 0PX;
        padding-bottom: 0px;
        line-height: 30px;
    }
    .p-dropdown-trigger {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        width: 26px;
    }
    .p-dropdown-trigger-icon {
        font-size: 11px;
    }
}

// panel dropdown
.p-dropdown-panel {
    border-radius: $border-radius;
    border-color: $neutral-300;
    .p-dropdown-items {
        padding: 0px;
        .p-dropdown-item.p-highlight {
            background: $neutral-50;
            color: $primary-700;
        }
        .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
            background-color: $neutral-50;
            border: 0px;
            color: $neutral-600;
        }
    }
}
// dropdown item
.p-ripple.p-element.p-dropdown-item {
    height: $item-height;
    padding-left: $padding-l;
    color: $neutral-600;
    font-size: 14px;
    line-height: $item-height;
    border-radius: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

input.p-dropdown-filter.p-inputtext.p-component{
  padding: 0;
}

.p-dropdown-clear-icon {
    top: 8px; 
    margin-top: 0rem !important;
    margin-right: 0rem !important;
    top: 8px !important;
    right: 25px !important;
}
