.p-toast {
    opacity: 1;
    box-shadow: none;
    border-radius: 8px;
    font-size: 14px;

    .p-toast-message {
        line-height: 20px;
        color: $neutral-800;
        font-weight: $fontRegular;
        border-width: 0;
        
        .p-toast-summary {
            font-weight: $fontMedium;
        }

        .p-toast-icon-close {
            color: $neutral-800;
            transition: none;
            opacity: 0.6;
            &:hover {
                opacity: 1;
                background: transparent;
            }
            &:focus {
                box-shadow: none;
            }
        }

        .p-toast-message-content {
            .p-toast-message-icon {
                font-size: 26px;
                margin-top: 2px;
            }
        }

        // SUCCESS
        &.p-toast-message-success {
            background: $success-50;
            border-color: transparent;
            border-width: 0;
            .p-toast-summary {
                color: $success-800;
            }
            .p-toast-detail {
                color: $neutral-800;
            }
            .p-toast-message-icon {
                color: $success-800;
                background-color: transparent;
            }
        }
        
        // ERROR
        &.p-toast-message-error {
            background: $error-50;
            border-color: transparent;
            border-width: 0;
            .p-toast-summary {
                color: $error-800;
            }
            .p-toast-detail {
                color: $neutral-800;
            }
            .p-toast-message-icon {
                color: $error-800;
                background-color: transparent;
            }
        }
        
        // WARNING
        &.p-toast-message-warn {
            background: $warning-50;
            border-color: transparent;
            border-width: 0;
            .p-toast-summary {
                color: $warning-800;
            }
            .p-toast-detail {
                color: $neutral-800;
            }
            .p-toast-message-icon {
                color: $warning-800;
                background-color: transparent;
            }
        }

        // INFO
        &.p-toast-message-info {
            background: $primary-50;
            border-color: transparent;
            border-width: 0;
            .p-toast-summary {
                color: $primary;
            }
            .p-toast-detail {
                color: $neutral-800;
            }
            .p-toast-message-icon {
                color: $primary;
                background-color: transparent;
            }
        }
    }
}
