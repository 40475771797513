.form-content {
    padding: 20px;
    .row-form {
        width: 100%;
        @include flexRow;
        padding-bottom: 15px;
        .col-form {
            width: 50%;
            &:first-child {
                padding-right: 20px;
            }
        }
        .col-m-form {
            flex: 10%;
            &:first-child, &:nth-child(9) {
                padding-right: 12px;
            }
        }

    }
    input,p-dropdown {
        width: 100%;
    }
    label {
        margin-bottom: 5px;
        white-space: nowrap;
        display: block;
    }

    .bottom-separator {
        border-bottom: 1px solid $neutral-200;
        margin-bottom: 15px;
    }
}

.footer-form {
    background-color: $primary-50;
    padding-right: 20px;
    text-align: right;
}

.panel-footer {
  height: 48px;
}

.footerSave {
  background-color: $primary-50;
  border-top: 1px solid $borderColor;
}
