.p-chip {
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
    padding: 0;
    color: $neutral-800;
    .p-chip-text {
        margin: 0;
        line-height: 24px;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;
    }
    .p-chip-icon {
        padding-right: 8px;
        padding-left: 12px;
        margin-right: 0px;
        font-size: 12px;
        line-height: 24px;
        &.waterpIcons-Undo {
            transform: rotate(90deg);
            margin-top: -3px;
        }
    }
    &.warning {
        color: $warning-900;
        background-color: $warning-200;
    }
    &.alert {
        color: $error-900;
        background-color: $error-200;
    }
    &.libelle {
        background-color: $primary-700;
    }
    &.libelleIntervention {
        background-color: $primary-700;
        color: white;
    }
    &.service, &.success {
        background-color: $success-300;
    }
    &.resilie, &.annule, &.neutral  {
        background-color: $neutral-300;
    }
    &.attente {
        background-color: $primary-100;
    }
    &.suspendu {
        background-color: $warning-300;
    }
    &.hidden {
        display: none;
    }
    &.alerte{
        border: 1px solid $error-700;
        background-color: $white;
        color: $error-700;
    }
}
