.k-grid * {
border-color: $neutral-100;
}

.kendo-grid-buttons {
  button {
    background-color: transparent;
  }
}
kendo-dateinput , .k-input-solid {
  border: 1px solid $neutral-300 !important;
}
.k-grid td {
  white-space: nowrap !important;
}
.k-grid-md td, .k-grid-md .k-table-td {
  padding-block: 4px;
}
.waterp-grid.k-grid td.k-selected,
.waterp-grid.k-grid .k-table-row.k-selected > td,
.waterp-grid.k-grid .k-table-td.k-selected,
.waterp-grid.k-grid .k-table-row.k-selected > .k-table-td {
  background-color: $primary-100;
}
.waterp-grid.k-grid .k-checkbox:checked,
.waterp-grid.k-grid .k-checkbox.k-checked {
  border-color: $primary-600;
  color: white;
  background-color: $primary-600;
}
.waterp-grid.k-grid .k-checkbox:indeterminate,
.waterp-grid.k-grid .k-checkbox.k-indeterminate {
  border-color: rgba(0, 0, 0, 0.08);
  color: $primary-600;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231b95c1' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M4,8 h8'/%3e%3c/svg%3e")
}
.waterp-grid.k-grid .k-checkbox:checked:focus,
.waterp-grid.k-grid .k-checkbox.k-checked.k-focus {
  box-shadow: 0 0 0 2px rgba(44, 176, 220, 0.3); //primary-400 with transparency
}

.k-dateinput {
    line-height: 30px;
    background-color: $white;
    border: 1px solid  $neutral-300 !important;
    border-radius: 6px;
    color: $neutral-800;
    padding: 0 5px 0 5px;
    width: 100%;
}

.loading-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.k-column-title {
  font-weight: 600;
}

.k-toolbar, .k-grouping-header, .k-header {
  background-color: white;
}

.k-grid-toolbar {
  border-color: $neutral-100;
}

button.k-pager-nav:focus:not(.mdc-switch), 
button.k-pager-nav:focus:not(.light-btn), 
button.k-pager-nav:focus:not(.outline-button) {
  color: #424242;
}

button.k-button-flat-primary:focus:not(.mdc-switch), 
button.k-button-flat-primary:focus:not(.light-btn), 
button.k-button-flat-primary:focus:not(.outline-button) {
  color: #097FAF;
}
