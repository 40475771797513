.waterp-tree {
  .p-tree {
    border: unset;
    padding: 0;
    .p-tree-container .p-treenode {
      padding: 0;
      .p-treenode-content {
        padding: 0;
        .p-tree-toggler:focus {
          box-shadow: inset 0 0 0 0.2rem $primary-100;
        }
        .p-treenode-label {
          width: 100%;
        }
      }
    }
  }
}
