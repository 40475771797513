//#region Tooltip
.p-tooltip {
    &.p-tooltip-top, &.p-tooltip-bottom, &.p-tooltip-right, &.p-tooltip-left {
        .p-tooltip-arrow {
            width: 0px;
            height: 0px;
        }
    }
    &.p-tooltip-top {
        .p-tooltip-arrow {
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-top: 5px solid $neutral-800;
        }
    }
    &.p-tooltip-bottom {
        .p-tooltip-arrow {
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            border-bottom: 5px solid $neutral-800;
        }
    }
    &.p-tooltip-right {
        .p-tooltip-arrow {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 5px solid $neutral-800;
        }
    }
    &.p-tooltip-left {
        .p-tooltip-arrow {
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid $neutral-800;
        }
    }
    .p-tooltip-text {
        text-align: center;
        border-radius: 5px;
        background: $neutral-800;
        font-size: 14px;
        color: $white;
        padding: 8px 13px 8px 13px;
    }
}
//#endregion
